import ApiService from './api.service'

export default class UsersService {

	async getUsers() {
        const response = await ApiService.get('api/users')
        return response.data.data
    }

	async getUsersForSelect(_params) {
        const response = await ApiService.get('api/users/select', _params)
        return response.data.data
    }

	async getPharmaciesForSelect() {
        const response = await ApiService.get('api/users/pharmacies/select')
        return response.data.data
    }
    
	async newUser(user) {
        const response = await ApiService.post('api/users', user)
        return response.data
    }

	async updateUser(user) {
        const response = await ApiService.put('api/users/'+user.id, user)
        return response.data.id
    }

	async deleteUser(id) {
        const response = await ApiService.delete('api/users/'+id)
        return response
    }

	async sendPasswordUser(email) {
        //const response = await ApiService.get('api/users/'+id+'/sendpassword')
        const response = await ApiService.post('api/users/sendaccesslink', { email: email })
        return response
    }

	async newPhar(phar) {
        const response = await ApiService.post('api/users/phar-attach', phar)
        return response.data.id
    }

	async deletePhar(phar) {
        const response = await ApiService.post('api/users/phar-detach', phar)
        return response
    }

}
