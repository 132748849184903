import ApiService from './api.service'

export default class OrderService {

    getProductsSmall() {
		//return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
	}

	async getOrders(_params) {
        const response = await ApiService.get('api/orders', _params)
        return response.data
    }

/*	async getOrdersForSelect(_params) {
        const response = await ApiService.get('api/orders/select', _params)
        return response.data.data
    }
*/

	async getNutriAndToolsForSelect(_params) {
        const response = await ApiService.get('api/orders/nutritools', _params)
        return response.data
    }

    async newOrder(order) {
        const response = await ApiService.post('api/orders', order)
        return response.data.id
    }

    async updateOrder(order) {
        const response = await ApiService.put('api/orders/'+order.id, order)
        return response.data.id
    }

	async deleteOrder(id) {
        const response = await ApiService.delete('api/orders/'+id)
        return response
    }

}
