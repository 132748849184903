<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>

				<!--Toolbar class="p-mb-0">
				</Toolbar-->

                <!-- Táblázat -->

				<DataTable ref="dt" :value="orders" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="40"
                            :loading="loading" @row-click="expandRow"
                            stateStorage="session" stateKey="dt-state-orders-session-3" @state-restore="onStateRestore($event)"
                            csvSeparator=";" :exportFilename="exportFilenameOrders"
                            v-model:filters="filters" filterDisplay="row" :globalFilterFields="['homecarer','postcode','city', 'city_district','address1','patient','pharmacy','order_item_name','fulfil_item_name']"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,40,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
                    <template #header>
                        <div class="table-header">
                            <div class="p-d-flex p-ai-center">
                                <Button :icon="(loading) ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'" class="p-button-raised p-button-sm p-mr-3" @click="setDefaultAndLoad()" 
                                        v-tooltip.right="'Szűrők törlése és frissítés'"/>
                                <h5 class="p-m-0 p-d-inline-block" style="padding-right: 3rem">
                                    Rendelések
                                </h5>
                                <Calendar id="datefrom" name="x-beteg-order-datum" v-model.lazy="datefrom" dateFormat="yy.mm.dd" style="width: 10rem" :monthNavigator="true" :yearNavigator="true"  yearRange="2000:2030"
                                        :showIcon="true" :showButtonBar="true" @change="loadLazyData()" @date-select="loadLazyData()" @clear-click="loadLazyData()" @today-click="loadLazyData()"
                                        autocomplete="off" :class="(notEmpty(datefrom)) ? 'l-filled-filter': ''"
                                ></Calendar>
                                <label style="margin-right: 0.5rem"> -tól </label>
                                <Calendar id="dateto" name="x-beteg-order-datumto" v-model.lazy="dateto" dateFormat="yy.mm.dd" style="width: 10rem" :monthNavigator="true" :yearNavigator="true"  yearRange="2000:2030"
                                        :showIcon="true" :showButtonBar="true" @change="loadLazyData()" @date-select="loadLazyData()" @clear-click="loadLazyData()" @today-click="loadLazyData()" 
                                        autocomplete="off" :class="(notEmpty(dateto)) ? 'l-filled-filter': ''"
                                ></Calendar>
                                <label for="dateto"> -ig</label>
                                <div class="p-d-inline" style="margin-left: 1.5rem; margin-right: 1.5rem">
                                    <InputSwitch id="showClosedSwitch" v-model="showClosed" @change="loadLazyData()"/>
                                    <label for="showClosedSwitch">Lezárt betegek is</label>
                                </div>                                    
                            </div>
                            <div>
                                <span class="p-input-icon-left">
                                    <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                    <InputText v-model="searchGlobal" placeholder="Keresés..." />
                                </span>
                                <div class="p-d-inline" style="margin-left: 1.5rem">
                                    <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                                </div>                                    
                            </div>
                        </div>
                    </template>

					<Column :expander="true" headerStyle="width: 3rem" @click.prevent="expandRow" />
                    <Column header="Megrendelve Teljesítve" headerStyle="width: 7.5rem" sortable :exportable="false">
                        <template #body="slotProps">
                            <span v-html="orderDateShow(slotProps.data)"></span>
                        </template>
                    </Column>
                    <Column v-for="col of exportColumns1" :field="col.field" :header="col.header" :key="col.field" :hidden="true">
                    </Column>
                    <Column field="patient" header="Beteg" :showFilterMenu="false" sortable>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''" :placeholder="`Keresés...`" v-tooltip.top.focus="'ENTER -> keresés'"/>
                        </template>
                    </Column>
                    <Column v-for="col of exportColumns2" :field="col.field" :header="col.header" :key="col.field" :hidden="true">
                    </Column>
                    <Column field="city_district" header="Város" headerStyle="min-width: 7.5rem; width:8%" :showFilterMenu="false" sortable>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''" :placeholder="`Keresés...`" v-tooltip.top.focus="'ENTER -> keresés'"/>
                        </template>
                    </Column>
                    <Column v-for="col of exportColumns3" :field="col.field" :header="col.header" :key="col.field" :hidden="true">
                    </Column>
                    <Column field="order_item_id" header="Rendelés" headerStyle="min-width: 7.5rem; width:18%" sortable :showFilterMenu="false" :exportable="false">
                        <template #body="slotProps">
                            <span v-html="orderItemNameShow(slotProps.data)"></span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="toolsAndAccs" optionLabel="name" optionValue="id" placeholder="Válassz..." 
                                scrollHeight="300px" class="p-column-filter" :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''">
                            </MultiSelect>
                        </template>
                    </Column>
                    <Column v-for="col of exportColumns4" :field="col.field" :header="col.header" :key="col.field" :hidden="true">
                    </Column>
                    <Column field="order_amount" header="Mennyiség" headerStyle="width: 6.5rem" :exportable="false">
                        <template #body="slotProps">
                            <span v-html="orderAmountShow(slotProps.data)"></span>
                        </template>
                    </Column>
                    <Column v-for="col of exportColumns5" :field="col.field" :header="col.header" :key="col.field" :hidden="true">
                    </Column>
                    <Column v-for="col of extraColumns" :field="col.field" :header="col.header" :key="col.field" sortable :showFilterMenu="false">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''" :placeholder="`Keresés...`" v-tooltip.top.focus="'ENTER -> keresés'"/>
                        </template>
                    </Column>
                    <Column field="status" header="Állapot" sortable :showFilterMenu="false" :exportable="false">
                        <template #body="slotProps">
                            <Badge :value="orderStatus[slotProps.data.status]" :class="'status-'+slotProps.data.status"></Badge>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="statusFilterOptions" optionLabel="label" optionValue="code" placeholder="Válassz..." 
                                scrollHeight="300px" class="p-column-filter" :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''">
                                <template #option="slotProps">
                                    <div class="p-multiselect-representative-option">
                                        <Badge :value="slotProps.option.label" :class="'status-'+slotProps.option.code"></Badge>
                                    </div>
                                </template>
                            </MultiSelect>
                        </template>
                    </Column>
                    <Column v-for="col of exportColumns6" :field="col.field" :header="col.header" :key="col.field" :hidden="true">
                    </Column>
                    <Column field="homecarer_note" header="Megjegyzés" headerStyle="min-width: 8rem; max-width: 11rem">
                        <template #body="slotProps">
                            <tag v-if="notEmpty(slotProps.data.homecarer_note)" value="Ápoló"/>
                            <tag v-if="notEmpty(slotProps.data.pharmacy_note)" value="Patika" severity="warning"/>
                            <tag v-if="notEmpty(slotProps.data.manager_note)" value="Manager" severity="danger"/>                            
                        </template>
                    </Column>
                    <Column v-for="col of exportColumns7" :field="col.field" :header="col.header" :key="col.field" :hidden="true">
                    </Column>
                    <Column headerStyle="min-width: 8rem; max-width: 11rem">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" label="Módosít" title="Módosít" class="p-button-secondary l-row-button" v-if="editable(slotProps.data.status)"
                                @click="editOrder(slotProps.data)"/>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col-5 p-grid">
                                    <div class="p-col-3" style="min-width: fit-content;">
                                    Beteg TAJ száma:
                                    </div>
                                    <div class="p-col-9">
                                        <span class="l-clickselect">{{ slotProps.data.tajcode }}</span>
                                            <Button icon="pi pi-copy" title="Másol" class="p-button-text" style="padding: 1px 2px; margin-left: 1px"
                                                    @click="copyTAJtoClipboard(slotProps.data.tajcode)"/>
                                    </div>
                                    <div class="p-col-3" style="min-width: fit-content;">
                                    Címe:
                                    </div>
                                    <div class="p-col-9">
                                        <span class="l-clickselect" v-html="fullAddress(slotProps.data)"></span>
                                    </div>
                                    <div class="p-col-3" style="min-width: fit-content;">
                                    Telefonszáma:
                                    </div>
                                    <div class="p-col-9">
                                        <span class="l-clickselect">{{ slotProps.data.tel1 }}</span>
                                        <span v-if="slotProps.data.tel2">, <span class="l-clickselect">{{ slotProps.data.tel2 }}</span></span>
                                        <span v-if="slotProps.data.rel1tel1">, <span class="l-clickselect">{{ slotProps.data.rel1tel1 }}</span></span>
                                    </div>
                                </div>
                                <div class="p-col-7 p-grid">
                                    <div class="p-col-2" v-if="notEmpty(slotProps.data.homecarer_note)">
                                        <tag value="Ápoló"/>
                                    </div>
                                    <div class="p-col-10" v-if="notEmpty(slotProps.data.homecarer_note)">
                                        <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.homecarer_note"></p>
                                    </div>
                                    <div class="p-col-2" v-if="notEmpty(slotProps.data.pharmacy_note)">
                                        <tag value="Patika" severity="warning"/>
                                    </div>
                                    <div class="p-col-10" v-if="notEmpty(slotProps.data.pharmacy_note)">
                                        <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.pharmacy_note"></p>
                                    </div>
                                    <div class="p-col-2" v-if="notEmpty(slotProps.data.manager_note)">
                                        <tag value="Manager" severity="danger"/>
                                    </div>
                                    <div class="p-col-10" v-if="notEmpty(slotProps.data.manager_note)">
                                        <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.manager_note"></p>
                                    </div>
                                </div>
                            </div>
                        </template>                            
                        </Card>
                    </template>
                </DataTable>


                <OrderDialog v-model:show="orderDialog" v-model:dialogData="dialogData" :beteg="{kcal_month: dialogData.kcal_month}"/>


                <!-- Törlés kérdés felugró
				<Dialog v-model:visible="deleteOrderDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="dialogData">Biztosan töröljük a megrendelést: <b>{{dialogData.fullname}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteOrderDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteOrder"/>
					</template>
				</Dialog -->

				<!--Dialog v-model:visible="deleteOrdersDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="order">Biztosan töröljük az összes kijelölt orvost/lejelentőt?</span>
					</div>
					<template #footer>
						<Button label="Nem" icon="pi pi-times" class="p-button-text" @click="deleteOrdersDialog = false"/>
						<Button label="Igen" icon="pi pi-check" class="p-button-text" @click="deleteSelectedOrders" />
					</template>
				</Dialog-->
			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, email, errorMessages } from  '@/texts/errorMessages'
import { store } from '@/store';
import OrderService from '@/service/backend/order.service'
import OrderDialog from '@/components/patient/OrderDialog'
import {FilterMatchMode} from 'primevue/api';

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
    components: {
        OrderDialog
    },
	data() {
		return {
			filters: null,
			orders: null,
			orderDialog: false,
			deleteOrderDialog: false,
			deleteOrdersDialog: false,
			dialogData: {},
			expandedRows: [],
            statusFilter: '',
            submitted: false,
            searchGlobal: "",
            totalRecords: 0,
            lazyParams: {datefrom: null, dateto: null},
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,
            showClosed: true,
            showOld: false,
            datefrom: null,
            dateto: null,

            extraColumns: [{field: 'homecarer', header: 'Ápoló'},{field: 'pharmacy', header: 'Patika'}],
            exportColumns1: [],
            exportColumns2: [],
            exportColumns3: [],

            orderStatus: {'1':'Megrendelt', '2': 'Feldolgozás alatt', '5':'Teljesítve', '6':'Lezárt', '8':'Elutasítva', '9':'Visszavont'},
            statusFilterOptions: [{code:'1',label:'Megrendelt'},{code:'2',label:'Feldolgozás alatt'},{code:'5',label:'Teljesítve'},{code:'6',label:'Lezárt'},{code:'8',label:'Elutasítva'},{code:'9',label:'Visszavont'}],
            toolsAndAccs: [],

            User: store.getters['user/User']

		}
	},
    validations: {
        order: {
            fullname: { required },
            title: { required }, 
            prof0: { required }, 
            prof2: { required }, 
            email: { email }
        }
    },
    orderService: null,
	created() {
		this.orderService = new OrderService()
        if(this.User.role == 'CARER'){
            this.extraColumns.splice(0, 1);
        }else
        if(this.User.role == 'PHARMACY'){
            this.extraColumns.splice(1, 1);
        }

        this.showClosed = localStorage.closed === "true" ? true : false
        this.datefrom = (localStorage.datefrom != undefined && localStorage.datefrom != null) ? localStorage.datefrom : this.$dayjs().subtract(3,'month').format('YYYY.MM.DD')
        this.dateto   = (localStorage.dateto   != undefined && localStorage.datefrom != null) ? localStorage.dateto   : null

        this.lazyParams = {
            first: 0,
            page: 1,
            rows: 40,
            sortField: 'order_date',
            sortOrder: -1,
            filters: null,
            closed:   (localStorage.closed === "true") ? 1 : 0
        }

        this.initFilters();

        this.loadLazyData();

    },
    beforeMount() {
        //this.loadCodeStore('orderClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
	},
	methods: {
        /*...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }),*/
        loadLazyData() {
            this.loading = true;

            delete this.lazyParams.originalEvent
            delete this.lazyParams.pageCount
            delete this.lazyParams.expandedRows
            delete this.lazyParams.expandedRowKeys

            this.lazyParams.filters = this.filters.global.value
            this.lazyParams.closed = (this.showClosed) ? 1 : 0
            if(this.datefrom != null){
                if(this.datefrom.length == 10)
                    this.lazyParams.datefrom = this.datefrom
                else
                    this.lazyParams.datefrom = this.$dayjs(this.datefrom).format('YYYY.MM.DD')
            }else{
                delete this.lazyParams.datefrom
            }
            if(this.dateto != null){
                if(this.dateto.length == 10)
                    this.lazyParams.dateto = this.dateto
                else
                    this.lazyParams.dateto = this.$dayjs(this.dateto).format('YYYY.MM.DD')
            }else{
                delete this.lazyParams.dateto
            }

            this.orderService.getOrders(this.lazyParams)
            .then( result => {
                result.data.data.forEach( d => {d.city_district = (d.city === "Budapest") ? d.city + " " + d.postcode.substr(1,2) : d.city; } ) // Itt töltjük fel, hogy megjelenítéskor legyen csak
                this.orders = result.data.data
                this.totalRecords = result.totalRecords
                this.initToolsAndAccs()
                this.loading = false
            })
        },
        initFilters() {
            let statusFilter = null
            if(this.isPharmacy)
                statusFilter = ["1", "2", "9"]
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'city_district': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'order_item_id': {value: null, matchMode: FilterMatchMode.IN},
                'patient': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'homecarer': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'pharmacy': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'status': {value: statusFilter, matchMode: FilterMatchMode.IN},
            }
        },
        initToolsAndAccs() {
            this.toolsAndAccs = []
            let t = this.toolsAndAccs
            let i = 0
            let found = false
            this.orders.forEach(o => {
                found = false
                for( i=0; i<t.length; i++ ){
                    if( t[i].id == o.order_item_id ){
                        found = true
                        break
                    }
                }
                if(!found){
                    t.push({
                        id: o.order_item_id,
                        name: o.order_item_name
                    })
                }
            });
        },
        setFiltersDefault(){
            this.lazyParams.sortField = 'order_date'
            this.lazyParams.sortOrder = -1
            this.lazyParams.filters = null
            this.lazyParams.closed = 0
            this.datefrom = this.$dayjs().subtract(3,'month').format('YYYY.MM.DD')
            this.dateto   = null
            this.initFilters();
        },
        setDefaultAndLoad() {
            this.setFiltersDefault()
            this.loadLazyData()
        },
        /*onPage(event) {
            this.lazyParams = event
            this.lazyParams.page++
            this.loadLazyData()
        },
        onSort(event) {
            this.onPage(event)
        },
        onFilter() {
            this.loading = true
            this.lazyParams.page = 1
            this.loadLazyData()
            this.loadingSearch = false
        },*/
        onStateRestore(event) {
            //console.log('state-restore')
            //console.log(event)
            if(!this.isPharmacy)
                this.lazyParams = event
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.orders.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        fullAddress(data){
            return data.postcode + '. ' + data.city + ', ' + data.address1
        },
        editable(status) {
            return  (this.User.role=="ADMIN" ||  
                     (this.User.role=="CARER" && (status==1 || status == 2)) ||
                     (this.User.role=="PHARMACY" && (status!=6 && status != 9))
                    )
        },
		editOrder(order) {
            this.dialogData = JSON.parse(JSON.stringify(order));
            this.submitted = false
            this.orderDialog = true
		},
		confirmDeleteOrder(order) {
			this.dialogData = order
			this.deleteOrderDialog = true
		},
		async deleteOrder() {
            this.submitted = true
            try{
                await this.orderService.deleteOrder(this.dialogData.id)
                this.orders = this.orders.filter(val => val.id !== this.dialogData.id)
                this.deleteOrderDialog = false
                this.dialogData = {}
                this.$toast.add({severity:'success', summary: 'Siker', detail: 'Order Deleted', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
		},
        orderDateShow(order) {
            //let date = "<span style='font-weight: 700'>" + order.order_date + "</span>"
            let date = "<span>" + order.order_date + "</span>"
            if(order.fulfil_date != "" && order.fulfil_date != null && order.fulfil_date)
                date += "<br/>" + order.fulfil_date
            return date
        },
        orderItemNameShow(order) {
            if(order.order_item_id == order.fulfil_item_id){
                return order.order_item_name
            }else{
                return "<span style='text-decoration: line-through;'>" + order.order_item_name + "</span><br/>" + order.fulfil_item_name
            }
        },
        orderAmountShow(order) {
            let amountType = "db"
            if(order.evtype === "N")    
                amountType = "kg"

            if(order.order_amount == order.fulfil_amount){
                return  order.order_amount + " " + amountType
            }else{
                return "<span style='text-decoration: line-through;'>" + order.order_amount 
                + " " + amountType + "</span><br/>" + order.fulfil_amount + " " + amountType
            }
        },
        notEmpty(val) {
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined && (val.length==undefined || val.length>0)
        },
        exportCSV() {
            this.orders.forEach( o => {o.status_name = this.orderStatus[o.status];              // Itt töltjük fel, hogy megjelenítéskor legyen csak
                                       o.tajcode_clean = (o.tajcode !== null) ? o.tajcode.replace(/-/g, '') : ""; 
                                    } ) 

            this.exportColumns1 = [{field:"order_date", header:"Megrendelve"}, {field:"fulfil_date", header:"Teljesítve"}]
            this.exportColumns2 = [{field:"tajcode_clean", header:"Beteg TAJ"}]
            this.exportColumns3 = [{field:"postcode", header:"IRSZ"}]
            this.exportColumns4 = [{field:"address1", header:"Cím"}, {field:"tel1", header:"Telefon 1"},{field:"tel2", header:"Telefon 2"}, {field:"rel1tel1", header:"Telefon - Hozzátartozó"}]
            this.exportColumns5 = [{field:"fulfil_item_name", header:"Rendelés"}]
            this.exportColumns6 = [{field:"fulfil_amount", header:"Mennyiség"}]
            this.exportColumns7 = [{field:"status_name", header:"Állapot"}]
            this.exportColumns8 = [{field:"manager_note", header:"Megjegyzés - Manager"}, {field:"pharmacy_note", header:"Megjegyzés - Patika"}]
            this.$refs.dt.exportCSV()
            this.exportColumns1 = []
            this.exportColumns2 = []
            this.exportColumns3 = []
            this.exportColumns4 = []
            this.exportColumns5 = []
            this.exportColumns6 = []
            this.exportColumns7 = []
            this.exportColumns8 = []
        },
        copyTAJtoClipboard(v){
            const modifiedString = v.replace(/-/g, '');

            navigator.clipboard.writeText(modifiedString)
                .then(() => {
                    this.$toast.add({severity:'success', summary: 'Siker', detail: 'A módosított szöveg másolva lett a vágólapra!', life: 3000})
                })
                .catch((err) => {
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba történt a vágólapra másolás közben!', life: 3000})           
                    console.error('Hiba történt a vágólapra másolás közben: ',err)
                });
        },
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'].value = val; this.loadingSearch = false }, 20)
            }, 600)
        },
        orderDialog: function(val) {
            if(!val && this.dialogData.id){

                const index = this.orders.findIndex(o => o.id == this.dialogData.id)

                if(this.dialogData.saved){
                    //console.log('saved')
                    let new_order = JSON.parse(JSON.stringify(this.dialogData));
                    new_order.fulfil_date = (this.dialogData.fulfil_date==undefined || this.dialogData.status == "1" || this.User.role!="CARER") ? null : this.dialogData.fulfil_date
                    new_order.order_item_id = this.dialogData.item.id
                    new_order.fulfil_item_id = (this.dialogData.fulfil_item_id==undefined || this.dialogData.status == "1" || this.User.role!="CARER") ? this.dialogData.item.id : this.dialogData.fulfil_item_id
                    new_order.order_item_name = this.dialogData.item.name 
                    new_order.fulfil_item_name = (this.dialogData.fulfil_item_name==undefined || this.dialogData.status == "1" || this.User.role!="CARER") ? this.dialogData.item.name : this.dialogData.fulfil_item_name
                    new_order.order_amount = this.dialogData.amount
                    new_order.fulfil_amount = (this.dialogData.fulfil_amount==undefined || this.dialogData.status == "1" || this.User.role!="CARER") ? this.dialogData.amount : this.dialogData.fulfil_amount
                    new_order.pharmacy_id = this.dialogData.pharmacy.id 
                    new_order.pharmacy = this.dialogData.pharmacy.name 
                    if(this.User.role=='ADMIN')
                        new_order.manager_note = this.dialogData.note
                    else 
                    if(this.User.role=='CARER')
                        new_order.homecarer_note = this.dialogData.note
                    else             // PHARMACY
                        new_order.pharmacy_note = this.dialogData.note
                                    
                    this.orders[index] = new_order

                }else
                if(this.dialogData.deletedLogically){
                    this.orders[index].status = "9"
                }else
                if(this.dialogData.deleted){
                    this.orders.splice(index, 1)
                }
                this.dialogData = {}
                
            }
        },
        showClosed(v) {
            localStorage.closed = v
        },
        datefrom(v){
            if(v != null)
                if(v.length==10)
                    localStorage.datefrom = v
                else
                    localStorage.datefrom = this.$dayjs(v).format('YYYY.MM.DD')
            else
                delete localStorage.datefrom
        },
        dateto(v){
            if(v != null)
                if(v.length==10)
                    localStorage.dateto = v
                else
                    localStorage.dateto =  this.$dayjs(v).format('YYYY.MM.DD')
            else
                delete localStorage.dateto
        }
    },
    computed: {
        /*...mapGetters({
            codesOrderClass: 'codeStore/orderClass'
        })*/
        isPharmacy: function(){
            return this.User.role == 'PHARMACY'
        },
        exportFilenameOrders: function(){
            return "Rendelesek_" + this.$dayjs().format('YYYY.MM.DD')
        }
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
.p-tag:not(:last-of-type) {
    margin-right: 5px;
}

</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
    margin: 1rem 0;
}

.order-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .order-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

}

.p-tag{
    margin: 3px 5px 3px 0px;
}

.l-row-button {
    visibility: hidden;
}
tr:hover .l-row-button {
    visibility: visible;
}

.p-badge{border-radius:2px;padding:.25em .5rem;text-transform:uppercase;font-weight:700;font-size:12px;letter-spacing:.3px; background-color:#c8e6c9;color:#256029;}
.p-badge.status-1{background-color:#b3e5fc;color:#23547b}
.p-badge.status-2{background-color:#feedaf;color:#8a5340}
.p-badge.status-5{background-color:#f3fff3;color:#47944c}
.p-badge.status-6{background-color:#a5a2a2;color:#eeeeee}
.p-badge.status-8{background-color:#ffcdd2;color:#c63737}
.p-badge.status-9{background-color:#dfdfdf;color:#c63737}
.p-badge.status-0{background-color:#eccfff;color:#694382}

/* orderStatus: {'1':'Megrendelt', '2': 'Feldolgozás alatt', '5':'Teljesítve', '6':'Lezárt', '8':'Elutasítva', '9':'Visszavont'}, */


#maincard {
    padding-top: 0px;
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }

}
</style>
